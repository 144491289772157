import React, { Fragment } from "react";
import IntroContent from "src/components/solutions/IntroContent";
import ConditionalWrapper from "src/components/helpers/ConditionalWrapper";
import { LocalizedLink } from "../commonComponents";

const IntroContentSection = ({ data, nid, entityId, path=null }) => {
  //Check if EMS page. To be worked on to be more scalable.
  const isEMS = entityId === "48";
  return data.map((intro, index) => {
    return (
      <Fragment key={index}>
        {intro.items &&
          intro.items.map((item) => (
            <Fragment key={item.nid}>
              {item.isLeftAndHasBullets ? (
                <IntroContent type="i" contentI={item} nid={item.nid} />
              ) : item.type === "d" ? (
                <IntroContent
                  type={item.type}
                  contentD={item}
                  nid={item.nid}
                  entityId={entityId}
                  fieldStyle={item.fieldStyle}
                />
              ) : item.type === "h" ? (
                <IntroContent type={item.type} contentH={item} nid={item.nid} path={path} fieldStyle={item.fieldStyle} />
              ) : item.type === "j" ? (
                <IntroContent
                  type={item.type}
                  contentJ={item}
                  nid={item.nid}
                  entityId={entityId}
                  fieldStyle={item.fieldStyle}
                />
              )
              :
              item.type === "k" ? (
                <IntroContent
                  type={item.type}
                  contentK={item}
                  nid={item.nid}
                  entityId={entityId}
                  fieldStyle={item.fieldStyle}
                  fieldGraphic={item?.fieldGraphic}
                  fieldLogoImages={item?.fieldLogoImages}

                />
              )
              :
              item.type === "l" ? (
                <IntroContent
                  type={item.type}
                  contentL={item}
                  nid={item.nid}
                  entityId={entityId}
                />
              ):
              (
                <IntroContent type={item.type} contentC={item} nid={item.nid} />
              )}
            </Fragment>
          ))}
        {!!intro.image && (
          <div className="mb-7" nid={nid} component="IntroContentItem">
            {intro.image}
          </div>
        )}
        {intro.bullets && isEMS && (
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="columns is-multiline is-centered has-word-break">
                {intro.bullets.map((bullet) => (
                  <div
                    className={`column ${
                      intro.hasBulletsMultipleOfThree ? "is-4" : "is-3"
                    }`}
                    key={bullet}
                  >
                    <div className="is-flex">
                      <span className="icon has-text-accruent-sapphire">
                        <i
                          className="fa fa-check-circle fa-2x"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <span className="is-size-6 has-text-left has-text-weight-semibold ml-2">
                        {bullet}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {intro.bullets && !intro.isLeftAndHasBullets && entityId !== "48" && (
          <ConditionalWrapper
            condition={intro.hasBulletsMultipleOfThree}
            wrapper={(children) => (
              <div className="columns">
                <div className="column is-10 is-offset-1-tablet">
                  {children}
                </div>
              </div>
            )}
          >
            <div className="columns is-multiline is-centered intro-bullets-section">
              {intro.bullets.map((bullet) => (
                <div
                  className={`column ${
                    intro.hasBulletsMultipleOfThree ? "is-4" : "is-3"
                  } has-text-centered intro-bullets`}
                  key={bullet}
                >
                  <div>
                    <i
                      className="fa fa-check-circle mt-2 fa-2x"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <p className="has-text-weight-semibold mt-4 has-word-break ">
                    {bullet}
                  </p>
                </div>
              ))}
            </div>
            {intro.cta && (
              <div className="mt-5 has-text-centered">
                <LocalizedLink
                  to={intro.cta.url.path}
                  className="is-primary break-word-keep-all"
                  isButton
                >
                  {intro.cta.title}
                </LocalizedLink>
              </div>
            )}
          </ConditionalWrapper>
        )}
      </Fragment>
    );
  });
};

export default IntroContentSection;
